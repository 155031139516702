.project-holder {
	width: 50%;
    height: auto;
	cursor: crosshair;
	margin-top: 35px;
    margin-bottom: 35px;
	float: left;
	position: relative;
}
.project-holder img{
    display: block;
}
.project-on-top {
	background: #fff;
	color: #000;
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	justify-content: center;
	align-items: center;
    display: flex;
    padding: 1%;
    position: absolute;
    opacity: 0;
    transition: all 200ms ease-in-out;
    top: 8px;
    bottom: 8px;
    left: 8px;
    right: 8px;
}
.project-on-top:hover {
    opacity: 0.75;
}
@media(max-width: 767px){
	.project-holder {
		width:100%;
		padding: 0.6%;
	}
}