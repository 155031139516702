.journal-box{
	width: 45%;
	margin: 2.5%;
	float: left;
}
/*
.journal-box img{
	box-shadow: 2px 2px 6px -2px rgba(169, 169, 169, 0.6);
	-moz-box-shadow:2px 2px 6px -2px rgba(169, 169, 169, 0.6);
    -webkit-box-shadow:2px 2px 6px -2px rgba(169, 169, 169, 0.6);
}
*/
.journal-box-info{
	margin-top: 15px;
	padding: 5px;
}
.j-title{
	font-size: 20px;
    font-weight: bold;
    line-height: 26px;
    color: #000;
    margin-bottom: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height:52px;
}
.j-info{
	border-bottom: 1px #e4e4e4 solid;
	font-size: 12px;
	letter-spacing: 0.5px;
	line-height: 20px;
	margin-bottom: 10px;
}
.j-list{
	font-size: 15px;
    line-height: 24px;
    margin-bottom: 25px;
    text-align:justify;
    text-justify: distribute;
	overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    height:91px;
}
.j-c-list{
	font-size: 15px;
    line-height: 24px;
}
.journal-content-boxcontainer{
	width: 900px;
	border: 5px #b3b3b3 solid;
	margin: 25px auto;
	position: relative;
}
.journal-content-info{
	width: 40%;
	background: #4c4c4c;
	position: absolute;
	top: 0;
	right: 0;
}
.journal-content-info dl{
	width: 100%;
	color: #fff;
	display: block;
	padding: 5px 15px 5px 15px;
	border-bottom: 1px #eee solid;
	float: left;
}
.journal-content-info dt{
	width: 35%;
	color: #fff;
	font-size: 15px;
	line-height: 24px;
	margin-right: 3%;
	float: left;
}
.journal-content-info dd{
	width: 62%;
	color: #fff;
	font-size: 15px;
	line-height: 24px;
	float: left;
}
.journal-content-info a{
	color: #fff;
	text-decoration: none;
}
.journal-content-info a:hover{
	color: #e4e4e4;
}
.journal-content-boxcontainer img{
	display: block;
	margin: auto;
	margin-top: 35px;
}
.journal-content{
	width: 100%;
	padding: 20px;
	text-align: justify;
    text-justify: distribute;
}
.j-c-title{
	width: 55%;
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
    color: #000;
    margin: 25px 0px 37px 0px;
    text-decoration: none;
}
@media(max-width: 1100px){
	.journal-content-boxcontainer{
		width: 97%;
	}	
}
@media(max-width: 767px){
	.journal-content-info{
		width: 100%;
	}
	.journal-content{
		padding-top: 120px;
	}
	.j-c-title{
		width: 100%;
	}
}
@media(max-width: 568px){
	.journal-content{
		padding: 135px 10px 10px 10px;
	}
	.journal-box{
		width: 100%;
		margin: 0;
		padding: 20px;
	}
	.journal-box-info {
		padding: 10px;
	}
	.j-list{
		height: auto;
	}
}