html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

html {
	scroll-behavior: smooth;
}

body {
	line-height: 1;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

img {
	max-width: 100%;
	height: auto;
	pointer-events: none;
}

* {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
}

body {
	font-family: Century Gothic, 微軟正黑體, Microsoft JhengHei;
}

.c-loader-bar {
	background-color: #e4e4e4;
	overflow: hidden;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100;
}

.c-loader-bar__indicator {
	border-radius: inherit;
	transform: translateX(-90%);
	animation: loading 0.4s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}

.c-loader-bar__indicator::before {
	content: '';
	display: block;
	height: 6px;
	background-color: #ffea00;
	border-radius: inherit;
	width: 130%;
	margin-left: auto;
	margin-right: auto;
}

@keyframes loading {
	from {
		transform: translate3d(-90%, 0, 0);
	}

	to {
		transform: translate3d(90%, 0, 0);
	}
}

.wrap {
	max-width: 1100px;
	margin: 0 auto;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.react-slidedown {
	position: sticky;
	top: 0;
	z-index: 89;
}

.header {
	width: 1100px;
	height: auto;
	background: #fff;
	padding-top: 15px;
	margin: auto;
}

.NMHlogo {
	width: 200px;
	display: block;
	float: left;
	margin-left: 10px;
	cursor: crosshair;
}

.socialmedia {
	width: auto;
	float: right;
	margin: 15px 0px 15px 0px;
}

.socialmedia li {
	width: 40px;
	margin-left: 5px;
	margin-right: 5px;
	float: left;
}

.socialmedia li a {
	display: block;
}

.toggle {
	height: 65px;
	width: 65px;
	position: relative;
	top: 32px;
	left: 22px;
	float: right;
	transform: translate(-50%, -50%);
	display: none;
	z-index: 90;
}

.toggle:hover {
	cursor: pointer;
}

.bar {
	height: 3px;
	width: 42px;
	position: absolute;
	top: 50%;
	left: 50%;
	border-radius: 50px;
	background-color: #000;
	transform: translate(-50%, -50%) rotate(0deg);
	transition: all ease 0.5s;
}

.bar::before,
.bar::after {
	content: "";
	position: absolute;
	height: inherit;
	border-radius: inherit;
	background-color: inherit;
	margin: auto;
	width: 50%;
	transition: all ease 0.5s;
}

.bar::before {
	top: -10px;
	left: 0;
	transform-origin: left;
}

.bar::after {
	bottom: -10px;
	right: 0;
	transform-origin: right;
}

.open {
	transform: translate(-50%, -50%) rotate(135deg);
}

.open::before {
	top: 0;
	transform: translateX(100%) rotate(-90deg);
}

.open::after {
	bottom: 0;
	transform: translateX(-100%) rotate(-90deg);
}

.topmenu {
	width: 350px;
	display: block;
	float: right;
	margin-top: 8px;
}

.hvr-underline-from-center {
	width: 70px;
	height: 50px;
	margin-left: 8px;
	margin-right: 8px;
	line-height: 60px;
	font-size: 15px;
	text-decoration: none;
	text-align: center;
	float: left;
	color: #000;
	cursor: crosshair;
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	overflow: hidden;
}

.hvr-underline-from-center:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 51%;
	right: 51%;
	bottom: 0;
	background: #ffea00;
	height: 4px;
	-webkit-transition-property: left, right;
	transition-property: left, right;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.hvr-underline-from-center:hover:before,
.hvr-underline-from-center:focus:before,
.hvr-underline-from-center:active:before {
	left: 0;
	right: 0;
}


.hvr-underline-from-center a,
.hvr-underline-from-center a:focus,
.hvr-underline-from-center a:active,
.hvr-underline-from-center a:hover {
	color: inherit;
	cursor: inherit;
	text-decoration: none;
}

.topmenu .on {
	width: 70px;
	height: 50px;
	margin-left: 8px;
	margin-right: 8px;
	line-height: 60px;
	font-size: 15px;
	text-decoration: none;
	text-align: center;
	float: left;
	color: #000;
	cursor: crosshair;
	display: inline-block;
	vertical-align: middle;
	border-bottom: 4px #ffea00 solid;
}

.clearfix {
	clear: both;
}

.menu {
	height: auto;
	background: #e4e4e4;
}

.menu li {
	padding-left: 45px;
	font-size: 14px;
	line-height: 40px;
	float: left;
	cursor: crosshair;
}

.menu a {
	text-decoration: none;
	color: #000;
	cursor: crosshair;
}

.menu a:hover {
	color: #0fb59c;
}

.menu .on {
	color: #0fb59c;
}

.container {
	flex: 1;
	padding-top: 15px;
}

.box-container {
	margin-bottom: 20px;
}

.box-container ul {
	display: flex;
	flex-wrap: wrap;
}

.box-container li {
	width: 20%;
	height: auto;
	padding: 0.3%;
	transition: 0.5s;
}

.box-container img {
	cursor: crosshair;
	display: block;
}

.box-container li:hover {
	padding: 1%;
}

.viewmore {
	font-size: 13px;
	display: block;
	color: #b2b2b2;
	text-align: center;
	text-decoration: none;
	padding: 8px;
	border-bottom: #ffea00 3px solid;
	cursor: pointer;
}

.viewmore:hover {
	color: #7b7b7b;
}

.l-title {
	font-size: 18px;
	font-weight: bold;
	color: #000;
	margin: 35px 0px 10px 15px;
}

.l-info dl {
	width: 100%;
	display: block;
	padding: 5px 15px 5px 15px;
	border-bottom: 1px #eee solid;
	float: left;
}

.l-info dt {
	width: 25%;
	font-size: 15px;
	color: #000;
	line-height: 24px;
	margin-right: 3%;
	float: left;
}

.l-info dd {
	width: 72%;
	font-size: 15px;
	color: #000;
	line-height: 24px;
	float: left;
}

.intro {
	margin-bottom: 30px;
}

.l-list {
	width: 80%;
	font-size: 15px;
	line-height: 24px;
	padding: 3px 15px;
}

.l-list-2 {
	font-size: 15px;
	line-height: 24px;
}

.l-list-e {
	font-size: 14px;
	line-height: 19px;
	color: #717171;
}

#footerBar {
	padding-bottom: 30px;
	;
}

.footer {
	border-top: 2px #efecec solid;
}

.footer-logo {
	width: 200px;
	padding: 10px;
	float: left;
}

.footer-list {
	font-size: 13px;
	color: #b2b2b2;
	line-height: 24px;
	padding: 10px;
	float: right;
}

.footer-list a {
	font-size: 13px;
	color: #b2b2b2;
	text-decoration: none;
	cursor: pointer;
}

.footer-copyright {
	width: 100%;
	text-align: center;
	font-size: 13px;
	color: #b2b2b2;
	margin-top: 50px;
}

#gotop {
	position: fixed;
	z-index: 90;
	right: 30px;
	bottom: 165px;
	display: block;
	width: 62px;
	height: 62px;
	cursor: pointer;
	filter: alpha(opacity=70);
}

#gotop.show {
	animation: fadein 0.5s;
	animation-fill-mode: backwards;
	opacity: 0.7;
}

#gotop.hide {
	animation: fadeout 0.5s;
	animation-fill-mode: backwards;
	pointer-events: none;
	opacity: 0;
}

.container.loading {
	display: flex;
	position: relative;
	height: 100%;
}

.container.loading>div:not(.loader) {
	display: none;
}

@keyframes fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 0.7;
	}
}

@keyframes fadeout {
	0% {
		opacity: 0.7;
	}

	100% {
		opacity: 0;
	}

}

@media(max-width: 1100px) {
	.header {
		width: 100%
	}
}

@media(max-width: 767px) {
	.header {
		padding-top: 0px;
	}

	.NMHlogo {
		width: 170px;
		margin-top: 6px;
	}

	.socialmedia {
		display: none;
	}

	.toggle {
		display: block;
	}

	.topmenu {
		position: fixed;
		background: #ffea00ed;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 0%;
		z-index: 1;
		overflow: hidden;
	}

	.topmenu.show {
		height: 100%;
		margin-top: 0px;
	}

	.hvr-underline-from-center {
		width: 100%;
		height: 80px;
		line-height: 110px;
		font-size: 18px;
		border-bottom: 2px #fff solid;
	}

	.hvr-underline-from-center:active,
	.hvr-underline-from-center:hover,
	.hvr-underline-from-center:focus {
		font-weight: bold;
	}

	.socialmedia.show {
		width: 100%;
		height: 50px;
		padding: 5px;
		display: flex;
		justify-content: center;
		background: #fff;
		position: fixed;
		bottom: 0;
		margin: 0;
		z-index: 2;
	}

	.socialmedia li {
		margin-left: 20px;
		margin-right: 20px;
	}

	.topmenu .on {
		width: 250px;
		height: 80px;
		line-height: 110px;
		font-size: 18px;
		border-bottom: 2px #fff solid;
		font-weight: bold;
	}

	.menu li {
		padding-left: 4.7%;
	}

	.box-container li {
		width: 33.3%;
		padding: 0.4%;
	}

	.l-info dd {
		width: 70%;
	}

	.l-list {
		width: 100%;
	}

	.footer-logo {
		margin: auto;
		float: none;
		padding: 5px;
	}

	.footer-list {
		display: none;
	}

	.footer-copyright {
		margin-top: 0px;
	}
}

@media(max-width: 568px) {
	.box-container li {
		width: 50%;
		padding: 0.7%;
	}
}