.about-design-list li{
	margin-top: 20px;
	width: 25%;
	padding: 1%;
	float: left;
}
.a-title{
	font-size: 18px;
    font-weight: bold;
    line-height: 21px;
    color: #000;
    text-align: center;
    padding: 7px;
}
@media(max-width: 767px){
	.about-design-list li{
		width: 50%;
		padding: 1%;
	}
}