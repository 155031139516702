.work-boxcontainer{
	margin: 0 auto;
	width: 1000px;
}
.work-boxcontainer img{
	margin: 5px auto;
	display: block; 
}
.work-description{
	width: 100%;
	padding: 40px 0px;
}
.work-description-list{
	width: 40%;
	float: left;
	padding-right: 10%;
}
.w-title{
	font-size: 18px;
    font-weight: bold;
    color: #000;
    margin-bottom: 12px;
}
.work-description-list p{
	border-top: 1px #e4e4e4 solid;
	font-size: 12px;
	letter-spacing: 0.5px;
	line-height: 20px;
}
.work-description-info{
	width: 60%;
	float: right;
}
@media(max-width: 1100px){
	.work-boxcontainer{
		width: 95%;
	}
}
@media(max-width: 767px){
	.work-description-list{
		width: 100%;
		float: none;
		padding: 0;
		margin-bottom: 30px;
	}
	.work-description-info{
		width: 100%;
		float: none;
	}
}
